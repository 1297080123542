import ApiService from "@/core/services/ApiService";
import { FormData } from "@/core/types/gws-master/master/medical-services/product/FormData";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";

export const useMedicalServiceFormStore = defineStore({
  id: "medicalServiceFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: "",
      stateUpdateId: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    formData(state) {
      return state.stateFormData;
    },
    error(state) {
      return state.stateError;
    },
    errors(state) {
      return state.stateErrors;
    },
    errorMessage(state) {
      return state.stateErrorMsg;
    },
  },
  actions: {
    async insertData(catId: string) {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          category_id: catId,
          description: this.formData.description,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/medical/products`,
          payload as AxiosRequestConfig
        );
        if (response.data) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async updateData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          category_id: this.formData.category_id,
          description: this.formData.description,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/medical/products/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async deleteData(id: string) {
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.delete(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/medical/products/${id}`
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    setFormData(data: FormData) {
      this.stateFormData = {
        name: data.name || "",
        category_id: data.category_id || "",
        description: data.description || "",
      };
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
  },
});
